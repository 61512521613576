import React from 'react'

import Page from '../../containers/DraggerGame/PlayGame'
import Seo from '../../containers/Seo'
import plainlaw from '../../games/plainlaw.json'

const Game = (props) => {
  return (
    <>
      <Seo.Plainlaw />
      <Page data={plainlaw} {...props} />
    </>
  )
}

export default Game
